import { React, useEffect, useState } from "react";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { geetingDetailsData, retreatsGalleryDetailsData } from "../../api/frontend";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { Celebration_URL } from "../../serverUrl";
const { detailBanner } = IMAGES;

export default function RetreatsDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [restaurantId, setRestaurantId] = useState("");
  const [value, setValue] = useState(0);
  const [isLoadingData, setIslodingData] = useState(true);

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [retreatIds, setRetreatsid] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsTitle, setRetreatsTitle] = useState("");
  const [gettingData, setGettingData] = useState([]);
  const [retreatsLocation, setRetreatsLocation] = useState("");

  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRetreatsid(response.data.data[0].id);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsTitle(response.data.data[0].title);
      setRetreatsLocation(response.data.data[0].other);

 
    } else {
    }
  };
  const handlegettingDetails  = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0])


    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    handleretreatsDetails(catagoryId);
    handlegettingDetails(catagoryId);
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Boutique Hotels, Villas, Resorts & Residences | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="Each Samadhi Retreats properties offer a unique sense of place, rooted in nature, culture and heritage. Discover more."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className=" retreats-detail-page">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={6}
                className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
              >
                <h3
                  className="cursor-btn"
                  onClick={() =>
                    navigate(`/${retreatsslug}?catagoryId=${retreatIds}`)
                  }
                >
                  {retreatsTitle && retreatsTitle !== undefined
                    ? `${retreatsTitle}, ${retreatsLocation}`
                    : ""}
                  <span className="ms-2">{subMenuArrow}</span>
                </h3>
              </Col>
              <Col lg={6}>
                <ul className="ps-0 pt-20 ">
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/rooms?retreatId=${retreatIds}`
                        )
                      }
                    >
                      Rooms
                    </Button>
                  </li>
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/dining?retreatId=${retreatIds}`
                        )
                      }
                    >
                      {" "}
                      Dining
                    </Button>
                  </li>
                 
                  <li>
                  
                    <Button
                      onClick={() =>
                        navigate(`/vskl-celebration?retreatId=${retreatIds}`)
                      }
                    >
                      {" "}
                      Wellness
                    </Button>
                  </li>
                  <li>
                  
                    <Button
                      onClick={() =>
                        navigate(
                          `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                        )
                      }
                    >
                      Offers
                    </Button>
                  </li>
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/gallery?retreatId=${retreatIds}`
                        )
                      }
                    >
                      Gallery
                    </Button>
                  </li>
                 
                </ul>
              </Col>
            </Row>
          </div>{" "}
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div "
              data-aos="fade-up"
              data-aos-duration="3000"
            >
             
              <img
                src={
                  gettingData.banner
                    ? `${Celebration_URL}${gettingData.banner}`
                    : detailBanner
                }
                alt=""
              />
            </div>
          </Container>
          <div className="tab_retreat_detail_page">
            <Container maxWidth="xl">
              <Row>
                <Col md={3}>
                  <Tabs
                    className="left_tabs_btn"
                    orientation="vertical"
                    // variant="scrollable"
                    value={value}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs example"
                  >
                    <Tab label={gettingData.first_title} {...a11yProps(0)} />
                    <Tab label={gettingData.second_title} {...a11yProps(1)} />
                    <Tab label={gettingData.third_title} {...a11yProps(2)} />
                    
                    <Tab label={gettingData.fourth_title} {...a11yProps(4)} />
                  </Tabs>
                </Col>
                <Col md={7}>
                  {" "}
                  <TabPanel
                    value={value}
                    index={0}
                    className="content_tab_getting"
                  >
                    <div
                      className="package_duration_texteditor"
                      dangerouslySetInnerHTML={{
                        __html: gettingData.first_content,
                      }}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    className="content_tab_getting"
                  >
                    <div
                      className="package_duration_texteditor"
                      dangerouslySetInnerHTML={{
                        __html: gettingData.second_content,
                      }}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={2}
                    className="content_tab_getting"
                  >
                    <div
                      className="package_duration_texteditor"
                      dangerouslySetInnerHTML={{
                        __html: gettingData.third_content,
                      }}
                    />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={3}
                    className="content_tab_getting"
                  >
                    <div
                      className="package_duration_texteditor"
                      dangerouslySetInnerHTML={{
                        __html: gettingData.fourth_content,
                      }}
                    />
                  </TabPanel>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
