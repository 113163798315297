import { Box, Container, Divider, Grid } from "@mui/material";
import { React, useEffect, useState } from "react";
import moment from 'moment/moment.js'
import { Link, useNavigate } from "react-router-dom";
import { privacyDetail } from "../../api/frontend";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

const PrivacyNotes = () => {
  const navigate = useNavigate();
  const [privacydata, setPrivacyData] = useState("");
  const [isLoadingData, setIslodingData] = useState(true);

  const handlePrivacy = async () => {
    setIslodingData(true);
    const response = await privacyDetail();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setPrivacyData(response.data.data[0])
    } else {
    }
  };
  useEffect(() => {
    handlePrivacy()
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{privacydata.meta_title}</title>
        <meta name="description" content={privacydata.meta_description} />
        <meta
          name="keywords"
          content="samadhi retreats privacy policy, privacy notes samadhi retreats, data protection, privacy information, personal data, user privacy, confidentiality, customer data protection, privacy practices samadhi retreats, information security samadhi retreats"
        />
        <link
          rel="canonical"
          href="https://www.samadhiretreats.com/privacy-notes"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="crms privacy-notes">
        <div className="experience-bg">
          <Container maxWidth="xl">
            <div className="crms-heading position-relative">
              <h3 className="font-62">Privacy Notice</h3>
            </div>
          </Container>
          <Container maxWidth="xl">
            <div className="mt-150" data-aos="fade-up" data-aos-duration="1500">
              <Grid container justifyContent="center">
                <Grid items xl={7} lg={9}>
                  <span>
                    Last updated:{" "}
                    {moment(privacydata.updated_at).format("D MMMM YYYY")}
                  </span>
                  <p
                    className="package_duration_texteditor"
                    dangerouslySetInnerHTML={{ __html: privacydata.text }}
                  ></p>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default PrivacyNotes;
