import { React, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { Container } from "@mui/material";

import Slider from "react-slick";
import { styled } from "@mui/material/styles";
import IMAGES from "../../../assets/images";
import SubHeader from "../../../components/layout/SubHeader";
import BannerSlider from "../../../components/BannerSlider";
import DatePicker from "react-multi-date-picker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { Base_URL } from "../../../serverUrl";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { celebrationdetails } from '../../../api/frontend'
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";
import LoaderMain from "../../../admin/component/loader";
import { Helmet } from "react-helmet";
const { eventbannernew } = IMAGES;

export default function CelebrationEventsTamarindHillSingapore1() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [eventType, setEventType] = useState("");
  const [venue, setVenue] = useState("");
  const [pax, setPax] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [BannerArray, setBannerArray] = useState([]);
  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [handlecelebrationTitle, setHandlecelebrationTitle] = useState("");
const [isLoadingData, setIslodingData] = useState(true);


  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "1px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage: "radial-gradient(#9e0000,#9e0000 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#fff",
    },
  });

  // Inspired by blueprintjs
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  const submitEnquiry = () => {
    var data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("phone", phoneNumber);
    data.append("eventType", eventType);
    data.append("venue", venue);
    data.append("pax", pax);
    data.append("eventDate", eventDate);
    data.append("description", description);

    var config = {
      method: "post",
      url: `${Base_URL}celebration_enquiry`,
      headers: {
        Accept: "application/json",
        token: "barrer ",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        swal("Request Send Successfully Added");
        window.location.reload(false);
      })
      .catch(function (error) {});
  };

  const handlecelebrationdetails = async (eventId) => {
    setIslodingData(true);

    const showexclusivePayload = {
      slug: eventId,
    };
    const response = await celebrationdetails(showexclusivePayload);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
       setBannerArray(JSON.parse(response.data.data[0].image))
       setRestaurantDetails(response.data.res)
      //  setCelebrationData(response.data.data[0]);
    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    const eventId = searchParams.get("id");
    setHandlecelebrationTitle(catagoryId);
    handlecelebrationdetails(eventId);
  }, [searchParams]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weddings & Events | Venues Kuala Lumpur | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="From private executive meetings to breathtaking jungle-enclave weddings, discover versatile event spaces  that's perfect for you."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="tamarind-celebration celebration-enquiry celebrate-singapore-page">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div mb-4">
              <SubHeader
                pageTitle="Celebrations & Events"
                subHeading={[
                  { id: "0", subLink: "Malaysia", subUrl: "#" },
                  { id: "1", subLink: "Singapore", subUrl: "#" },
                ]}
              />
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h2 className="text-center ">{handlecelebrationTitle}</h2>
              <BannerSlider BannerArray={BannerArray} />
            </div>
          </Container>
          <div className="enquiry-form ">
            <Container maxWidth="xl">
              <div className="enquirt-section border-dashed-ex pt-270">
                <Grid container spacing={0} justifyContent="center">
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-once="true"
                  >
                    <h3 className="text-center">Enquiry</h3>
                    <Stack direction="column" spacing={2}>
                      <input
                        placeholder="First Name"
                        className="enquiry-input"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        placeholder="Last Name"
                        className="enquiry-input"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <input
                        placeholder="Email"
                        className="enquiry-input"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        placeholder="Phone number"
                        className="enquiry-input"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <input
                        placeholder="Type of Event"
                        className="enquiry-input"
                        onChange={(e) => setEventType(e.target.value)}
                      />
                      <DatePicker
                        placeholder="Select date of event &#10095;"
                        className="date-input"
                        // value={eventDate}
                        onChange={(e) => setEventDate}
                      />
                      <div className="pack_div_new mb-3">
                        <FormLabel className="me-3 mb-4">
                          Select Venue
                        </FormLabel>
                        <Row>
                          {restaurantDetails?.map((item) => (
                            <Col md={6}>
                              {" "}
                              <div className="d-flex align-items-start">
                                <FormControlLabel
                                  value={item.id}
                                  control={<BpRadio />}
                                  checked={pax === item.id}
                                  onChange={(e) => setPax(item.id)}
                                />
                                <div className="venue_text_div">
                                  <h5>
                                    {item.title}, {item.restaurant}
                                  </h5>
                                  <p>Venue Capacity</p>
                                  <p>
                                    Indoor Seating |{" "}
                                    {item.indoor ? item.indoor : 0} guests
                                  </p>
                                  <p>
                                    Outdoor Seating |{" "}
                                    {item.outdoor ? item.outdoor : 0} guests
                                  </p>

                                  <p>
                                    Standing Reception |{" "}
                                    {item.reception ? item.reception : 0} guests
                                  </p>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>

                     

                      <textarea
                        placeholder="Message"
                        className="enquiry-textarea"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <button className="btn-send" onClick={submitEnquiry}>
                        Send
                      </button>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
