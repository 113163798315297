import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { subMenuArrow } from "../../utils/svg.file";
import { Col, Row, Button } from "react-bootstrap";

import styles from "./cruises.module.css";
import BannerSlider from "../../components/BannerSlider";
import {
  CruiseLanding,
  Cruisepackage,
  CruisepackageList,
  exclusiveoffers,
  exclusiveofferswithId,
  showExperienceIdsList,
  showExperienceList,
} from "../../api/frontend";
import { useState } from "react";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

const {
  cruiseBanner,
  cruOne,
  cruTwo,
  cruThree,

  dayTripOne,
  dayTripTwo,
  dayTripThree,
  experience1,
  experience9,
  Offer1,
  Offer2,
  Offer3,
  truck,
} = IMAGES;
const dayTrips = [
  {
    image: Offer1,
    type: "CRUISES",
    title: "Daytrip",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: Offer2,
    type: "CRUISES",
    title: "Wedding, Events & Activities",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: Offer3,
    type: "CRUISES",
    title: "Food Tour",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
  {
    image: dayTripOne,
    type: "DAYTRIPS",
    title: "Fraser Hill",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "1",
    speed: "3000",
    dataspeed: "fade-down",
  },
];

const deckPlanArray = [truck, truck, truck, truck];
export default function Cruises() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoadingData, setIslodingData] = useState(true);
  const [landingData, setLandingData] = useState("");
  const [category, setCategory] = useState("");
  const [bannerData, setBannerData] = useState([]);
  const [cruiseCards, setCruiseCards] = useState([]);
  const [cruisePackageCards, setCruisePackageCards] = useState([]);
  

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 10,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  window.addEventListener("scroll", () => {
    let elem = document.getElementById("keyvisual");
    let scrollY = window.scrollY / 10;
    elem.style.backgroundSize = 100 + scrollY + "%";
  });
  const handleCruiseDetails = async () => {
    setIslodingData(true);

    const response = await CruiseLanding();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setLandingData(response.data.data[0]);
      setBannerData(JSON.parse(response.data.data[0].slider));
    } else {
    }
  };
  const handleCruisePackage = async () => {
    setIslodingData(true);

    const response = await Cruisepackage();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCruiseCards(response.data.data);
      // setLandingData(response.data.data[0])
      // setBannerData(JSON.parse(response.data.data[0].slider))
    } else {
    }
  };
  const handleCruisePackagelist = async () => {
    setIslodingData(true);

    const response = await CruisepackageList();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCruisePackageCards(response.data.data);
      // setLandingData(response.data.data[0])
      // setBannerData(JSON.parse(response.data.data[0].slider))
    } else {
    }
  };
  useEffect(() => {
    handleCruiseDetails();
    handleCruisePackage();
    handleCruisePackagelist();
  }, []);
  let rows = [];

  cruiseCards?.map((item, index) => {
    rows.push({
      image: item.cardImage,
      subcategory: "Activity",
      title: item.title,
      status: "activity",
      shortDescription: item.shortDescription,
      id: item.id,
      speed: "2000",
      dataspeed: "fade-up",
    });
  });
  cruisePackageCards?.map((item, index) => {
    rows.push({
      image: item.cardImage,
      subcategory: "Package",
      title: item.title,
      shortDescription: "",
      status: "package",
      id: item.id,
      speed: "2000",
      dataspeed: "fade-up",
    });
  });
  const [travelArray, setTravelArray] = useState([]);
  const handleexclusive = async () => {
    setIslodingData(true);
    const id = "3";
    const response = await exclusiveofferswithId(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setTravelArray(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleexclusive();
  }, []);

  const [experienceListArray, SetExperienceListArray] = useState([]);
  const handleExperienceList = async () => {
    const id = "4";
    const response = await showExperienceIdsList(id);

    if (response.remote === "success") {
      SetExperienceListArray(response.data.data);
    } else {
    }
  };
  const handleChnage = (id, status) => {
    if (status === "activity") {
      navigate(`/cruises-activities?package=${id}`);
    } else {
      navigate(`/cruise/cruise-charter?package=${id}`);
    }
  };
  useEffect(() => {
    handleExperienceList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIslodingData(false);
    }, 3000);
    const catagoryId = searchParams.get("category");
    setCategory(catagoryId);
  }, [searchParams]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{landingData.meta_title}</title>
        <meta name="description" content={landingData.meta_description} />
        <link
          rel="canonical"
          href="https://www.samadhiretreats.com/samadhi-cruises"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="cruise-page cruise-home">
        <div className="room-type-page main-bg">
         
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3
                  onClick={() => navigate("/samadhi-cruises")}
                  className=" curor-btn"
                >
                  Cruises
                </h3>
              </Col>
              
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
              // data-aos-once="true"
            >
              <img src={`${Offers_URL}${landingData?.banner}`} alt />
            </div>
          </Container>
          <div className="page-description-div">
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={6}
                  className="page-description-data text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  data-aos-once="true"
                >
                  <h2 className="geting-text"> {landingData?.title}</h2>
                  <p
                    className="package_duration_texteditor"
                    dangerouslySetInnerHTML={{
                      __html: landingData?.description,
                    }}
                  />
                  <Button
                    className="dashed-btn mt-4"
                    onClick={() => navigate("/cruise/cruise-charter")}
                  >
                    Enquiry
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="taste-center-card pb-15">
            <Container maxWidth="xl">
              <div className="normal-card-section deck-main">
                {!category ? (
                  <>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid md={5} item>
                        <div className="text-center deck-plan">
                          <h3 className="geting-text">Deck Plan</h3>
                          <p>{landingData?.deckPlan}</p>
                        </div>
                      </Grid>
                    </Grid>
                    <div className="deck-plan-slider">
                      <Grid container spacing={4} justifyContent="center">
                        <Grid item md={10}>
                          <BannerSlider BannerArray={bannerData} />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {category ? (
                  <>
                    <div className="normal-card-section">
                      <Grid container spacing={4}>
                        {rows.map((content) => {
                          if (category) {
                            if (content.status !== category) {
                              return;
                            }
                          }

                          return (
                            <>
                              <Grid
                                xs={12}
                                item
                                md={6}
                                className="normal-card-div cursor-btn "
                                key={content.id}
                                data-aos={content.dataspeed}
                                data-aos-duration={content.speed}
                                onClick={() =>
                                  handleChnage(content.id, content.status)
                                }
                              >
                                <OfferSlider {...content} />
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {!category ? (
                  <>
                    <div className="normal-card-section">
                      <Grid container spacing={4}>
                        <Grid item md={6} style={{ marginTop: "200px" }}>
                          <Grid container spacing={4}>
                            {rows.map((content) => {
                              return (
                                content.status === "package" && (
                                  <>
                                    <Grid
                                      xs={12}
                                      item
                                      md={12}
                                      className="normal-card-div cursor-btn "
                                      key={content.id}
                                      data-aos="fade-up"
                                      data-aos-duration="2000"
                                      data-aos-once="true"
                                      onClick={() =>
                                        handleChnage(content.id, content.status)
                                      }
                                    >
                                      <OfferSlider {...content} />
                                    </Grid>
                                  </>
                                )
                              );
                            })}
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Grid container spacing={4}>
                            {rows.map((content) => {
                              return (
                                content.status === "activity" && (
                                  <>
                                    <Grid
                                      xs={12}
                                      item
                                      md={12}
                                      className="normal-card-div cursor-btn "
                                      key={content.id}
                                      data-aos="fade-up"
                                      data-aos-duration="1500"
                                      data-aos-once="true"
                                      onClick={() =>
                                        handleChnage(content.id, content.status)
                                      }
                                    >
                                      <OfferSlider {...content} />
                                    </Grid>
                                  </>
                                )
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Container>
          </div>
          <div className="day-trips-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Offers</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={travelArray.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {travelArray?.map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div "
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      data-aos-once="true"
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
