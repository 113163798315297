import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
function Content({ title, description, id, descriptionTitle, linkUrl }) {
  return (
    <>
      {" "}
      <div className="cards heading ">
        <h4>{title}</h4>
        <p className="my-3">{descriptionTitle}</p>
        <p
          className="mb-5 package_duration_texteditor"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <Link to={linkUrl} className="dashed-btn">
        Enquiry
      </Link>
    </>
  );
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  descriptionTitle: PropTypes.string,
};

export default Content;
