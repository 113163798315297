import { React, useEffect, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import BannerSlider from "../../components/BannerSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import {
  showfeaturedstory,
  celebrationBanners,
  celebrationBlock,
  retreatsDetailsData,
  retreatsGalleryDetailsData,
  geetingDetailsData,
} from "../../api/frontend";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const {
  customerPic,
  celebrateOne,
  stayBanner,
  vsklBanner,
  motor1,
  motor2,
  motor3,
  motor4,
  motor5,
  experience1,
  experience3,
  celebrationBanner,
  experience8,
  LineOne,
  LineTwo,
  LineFive,
  LineFour,
  LineThree,
  LineSix,
} = IMAGES;

export default function VSKLCelebration() {
  const navigate = useNavigate();
  // const BannerArray = [celebrationBanner, stayBanner, vsklBanner, celebrateOne];
  const [experienceArray, SetexperienceArray] = useState([]);
  const [BannerArray, setBannerArray] = useState([]);
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [retreatsDetails, setRetreatsDetails] = useState([]);
  const [retreatsRoomArray, setRetreatsRoomArray] = useState([]);
  const [retreatsTitle, setRetreatsTitle] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsLocation, setRetreatsLocation] = useState("");
  const [searchParams] = useSearchParams();
  const [restaurantId, setRestaurantId] = useState("");
  const [isLoadingData, setIslodingData] = useState(true);
  const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [retreatsMetaTitleData, setRetreatsMetaTitleData] = useState("");
  const [retreatsMetaDiscriptionData, setRetreatsMetaDiscriptionData] =
    useState("");
  const [retreatsaccomodationBanner, setRetreatsaccomodationBanner] = useState(
    []
  );
  const [retreatsaccomodationDescription, setRetreatsaccomodationDescription] =
    useState([]);
  const handleCelebrationBanner = async () => {
    setIslodingData(true);

    const response = await celebrationBanners();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setBannerArray(response.data.data);
    } else {
    }
  };
  const handleCelebrationBlock = async () => {
    setIslodingData(true);

    const response = await celebrationBlock();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleCelebrationBanner();
    handleCelebrationBlock();
  }, []);

  let rows = [];

  BlockCelebration?.forEach((item, index) => {
    if (
      Number(item.locationId) === Number(restaurantId) &&
      item.offerType === "retreats"
    )
      rows.push({
        image: item.cardimage,
        type: "CELEBRATIONS & EVENTS",
        title: item.title,
        shortDescription: item.description,
        id: item.id,
        other: item.other,
        speed: "fade-up",
        dataspeed: "1500",
      });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.data.exclusive.length === 0) {
      navigate("/error");
    }
    setExclusiveOffers(response.data.exclusive);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setRetreatsTitle(response.data.data[0].title);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsLocation(response.data.data[0].other);
      setRetreatsMetaTitleData(response.data.data[0].meta_title);
      setRetreatsMetaDiscriptionData(response.data.data[0].meta_description);
      setRetreatsaccomodationBanner(
        JSON.parse(response.data.data[0].wellness_gallery)
      );
      setRetreatsaccomodationDescription(
        JSON.parse(response.data.data[0].wellness_description)
      );
    } else {
    }
  };
  const exclusiveOffer = [];
  exclusiveOffers?.map((item) => {
    exclusiveOffer.push({
      image: item.image,
      subcategory: "OFFERS",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      slug: item.other,
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0]);
    } else {
    }
  };
  const [scriptItem, setScriptItem] = useState('');
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    handleretreatsDetails(catagoryId);
    setRestaurantId(catagoryId);
    handlegettingDetails(catagoryId);
    setScriptItem(catagoryId === '17' 
      ? `
          (function () {
          var params = {
              hpr: "JapaMala Resorts",
              hpid: "65c451e2-21c7-454a-8fab-d3dd692d78e8",
              sha256_eml: "",
              sha1_eml: "",
              md5_eml: "",
              ccid: "",
              pt: ""
          };
          params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
          try { params = Object.assign({}, sjrn_params, params); } catch(e) {}
          var paramsArr = [];
          for (key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])); }
          var pl = document.createElement('iframe');
          pl.type = 'text/html';
          pl.setAttribute('style', 'height:0; width:0; display:none;');
          pl.async = true;
          pl.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })();`
      : `window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
         sjn('initAndFire', 'advertiser', '15207', 'tracking', { 
             context: { vertical: 'hotel' },
             params: {}
         });`
  );
  }, [searchParams]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{retreatsMetaTitleData}</title>
        <meta name="description" content={retreatsMetaDiscriptionData} />

        <script src="https://static.sojern.com/sdk/latest/sojern.min.js"></script>
          <script src='https://static.sojern.com/utils/sjrn_autocx.js'></script>
          <script type="text/javascript">{scriptItem}</script>
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="celebration-and-event-page celebration-home retreats-celebration wellness-vskl">
        <div className=" main-bg">
          <Container>
            <div className="sub-header sub-header-div">
              <Row className="g-0">
                <Col
                  lg={6}
                  className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                >
                  <h3
                    className="cursor-btn"
                    onClick={() =>
                      navigate(`/${retreatsslug}?catagoryId=${restaurantId}`)
                    }
                  >
                    {retreatsTitle && retreatsTitle !== undefined
                      ? `${retreatsTitle}, ${retreatsLocation}`
                      : ""}
                    <span className="ms-2">{subMenuArrow}</span>
                  </h3>
                </Col>
                <Col lg={6}>
                  <ul className="ps-0 pt-20 pb-15">
                    <li>
                    
                      <Button
                        onClick={() =>
                          navigate(
                            `/${retreatsslug}/rooms?retreatId=${restaurantId}`
                          )
                        }
                      >
                        Rooms
                      </Button>
                    </li>
                    <li>
                    
                      <Button
                        onClick={() =>
                          navigate(
                            `/${retreatsslug}/dining?retreatId=${restaurantId}`
                          )
                        }
                      >
                        {" "}
                        Dining
                      </Button>
                    </li>
                   
                    <li>
                      <Button
                        className="active"
                        onClick={() =>
                          navigate(
                            `/vskl-celebration?retreatId=${restaurantId}`
                          )
                        }
                      >
                        {" "}
                        Wellness
                      </Button>
                    </li>
                    <li>
                     
                      <Button
                        onClick={() =>
                          navigate(
                            `/exclusive-offer-details?retreatId=${restaurantId}&type=retreats`
                          )
                        }
                      >
                        Offers
                      </Button>
                    </li>
                    <li>
                     
                      <Button
                        onClick={() =>
                          navigate(
                            `/${retreatsslug}/gallery?retreatId=${restaurantId}`
                          )
                        }
                      >
                        Gallery
                      </Button>
                    </li>
                    <li>
                      <Button onClick={() => navigate(`/travels-tours`)}>
                        Activities
                      </Button>
                    </li>
                   
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div className="banner-div  ">
              <h2 className="text-center  ">Wellness</h2>
              <div data-aos="fade-up" data-aos-duration="3000">
                <BannerSlider
                  BannerArray={
                    retreatsaccomodationBanner !== "null"
                      ? retreatsaccomodationBanner
                      : []
                  }
                />
              </div>
              <div
                className="text-center mt-5"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-duration="1500"
              >
                {retreatsaccomodationDescription?.map((item, index) => (
                  <>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item md={8} className="mb-5">
                        <h3 class="text-center  ">{item.wellnessTitle}</h3>
                        <p>{item.wellnessDescription}</p>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </div>
            </div>
          </Container>

          <div className="day-trips-section ">
            <Container maxWidth="xl">
              <div className="day-trip-section-bordered btn-slider-div ">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={exclusiveOffer.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                >
                  {exclusiveOffer.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div  cursor-btn"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      data-aos-once="true"
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="">
            {" "}
            <Container maxWidth="xl">
              <div className="book-offer-div">
                <Grid
                  container
                  spacing={3}
                  className="text-center section-head align-items-center"
                >
                  <Grid md={3}></Grid>
                  <Grid md={3}>
                    <h3>Offers</h3>
                  </Grid>
                  <Grid md={3}>
                    <Button className="white-btn">Book Now</Button>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
