import { React, useEffect, useRef, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import Masonry from "@mui/lab/Masonry";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GalleryModal from "./GalleryModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  retreatsDetailsData,
  retreatsGalleryDetailsData,
  RetreatSection,
  geetingDetailsData,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import { Col, Row, Button } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
const { mapImg, vsklGallery, vsklgallery2, vsklgallery3, other1 } = IMAGES;

export default function VSKLGallery() {
  const galleryref = useRef();
  const navigate = useNavigate();
  const [imagesOpen, setImagesOpen] = useState(false);
  const [clicked, setClicked] = useState(0)
  const handleGalleryModalOpen = () => setImagesOpen(true);
  const handleGalleryModalClose = () => setImagesOpen(false);
  const [retreatsDetails, setRetreatsDetails] = useState([]);
  const [retreatsDetailssection, setRetreatsDetailssection] = useState([]);
  const [retreatIds, setRetreatsid] = useState("");
  const [retreatsTitle, setRetreatsTitle] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsLocation, setRetreatsLocation] = useState("");
  const [searchParams] = useSearchParams();
const [isLoadingData, setIslodingData] = useState(true);
const [retreatsMetaTitleData, setRetreatsMetaTitleData] = useState('');
const [retreatsMetaDiscriptionData, setRetreatsMetaDiscriptionData] = useState('');
  // const [MotorBikeArray, setMotorBikeArray] = useState([]);
  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRetreatsDetails(JSON.parse(response.data.data[0].gallery));
      setRetreatsid(response.data.data[0].id);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsTitle(response.data.data[0].title);
      setRetreatsLocation(response.data.data[0].other);
      setRetreatsMetaTitleData(response.data.data[0].meta_title);
      setRetreatsMetaDiscriptionData(response.data.data[0].meta_description);
    } else {
    }
  };

  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0])


    } else {
    }
  };
  const [scriptItem, setScriptItem] = useState('');
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    handleretreatsDetails(catagoryId);
    handlegettingDetails(catagoryId);
    setScriptItem(catagoryId === '17' 
      ? `
          (function () {
          var params = {
              hpr: "JapaMala Resorts",
              hpid: "65c451e2-21c7-454a-8fab-d3dd692d78e8",
              sha256_eml: "",
              sha1_eml: "",
              md5_eml: "",
              ccid: "",
              pt: ""
          };
          params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
          try { params = Object.assign({}, sjrn_params, params); } catch(e) {}
          var paramsArr = [];
          for (key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])); }
          var pl = document.createElement('iframe');
          pl.type = 'text/html';
          pl.setAttribute('style', 'height:0; width:0; display:none;');
          pl.async = true;
          pl.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })();`
      : `window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
         sjn('initAndFire', 'advertiser', '15207', 'tracking', { 
             context: { vertical: 'hotel' },
             params: {}
         });`
  );
  }, [searchParams]);

  const handleretreatsDetailssection = async () => {
    setIslodingData(true);

    const response = await RetreatSection();

    if (response.remote === "success") {
    setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRetreatsDetailssection(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleretreatsDetailssection();
  }, []);

  const MotorBikeArray = [];
  retreatsDetailssection?.forEach((item, index) => {
    if (item.id !== retreatIds)
      MotorBikeArray.push({
        image: item.cardImage,
        subcategory: "STAYS",
        title: item.title,
        slug: item.slug,
        shortDescription: item.shortDescription,
        id: item.id,
        speed: "1500",
        dataspeed: "fade-up",
      });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min:768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,

    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{retreatsMetaTitleData}</title>
        <meta name="description" content={retreatsMetaDiscriptionData} />
        <script src="https://static.sojern.com/sdk/latest/sojern.min.js"></script>
        <script src='https://static.sojern.com/utils/sjrn_autocx.js'></script>
            <script type="text/javascript">{scriptItem}</script>
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="travel-category vskl-gallery">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={6}
                className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
              >
                <h3
                  className="cursor-btn"
                  onClick={() =>
                    navigate(`/${retreatsslug}?catagoryId=${retreatIds}`)
                  }
                >
                  {retreatsTitle && retreatsTitle !== undefined
                    ? `${retreatsTitle}, ${retreatsLocation}`
                    : ""}

                  <span className="ms-2">{subMenuArrow}</span>
                </h3>
              </Col>
              <Col lg={6}>
                <ul className="ps-0 pt-20 ">
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/rooms?retreatId=${retreatIds}`
                        )
                      }
                    >
                      Rooms
                    </Button>
                  </li>
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/dining?retreatId=${retreatIds}`
                        )
                      }
                    >
                      {" "}
                      Dining
                    </Button>
                  </li>
                 
                  <li>
                   
                      {retreatIds !== 18 ? <>
                    <Button
                      onClick={() =>
                        navigate(
                          `/vskl-celebration?retreatId=${retreatIds}`
                        )
                      }
                    >
                      {" "}
                      Wellness
                    </Button>
				  </>:""}
                  </li>
                  <li>
                   
                    <Button
                      onClick={() =>
                        navigate(
                          `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                        )
                      }
                    >
                      Offers
                    </Button>
                  </li>
                  <li>
                    
                    <Button
                      className="active"
                      onClick={() =>
                        navigate(
                          `/${retreatsslug}/gallery?retreatId=${retreatIds}`
                        )
                      }
                    >
                      Gallery
                    </Button>
                  </li>
                  <li>
                    <Button onClick={() => navigate(`/travels-tours`)}>
                      Activities
                    </Button>
                  </li>
                 
                </ul>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl">
            <div className="border-btm"></div>
          </Container>
          <div className="gallery-div-massonary-min">
            <>
              <div className="gallery-div-masonary">
                <Masonry
                  columns={{ xs: 1, md: 3 }}
                  spacing={{ xs: 0, md: 4 }}
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="3000"
                  sx={{
                    px: 1,
                    maxWidth: { xs: "1440px", lg: "1200px", xl: "1440px" },
                    mx: { xs: "auto", lg: "auto" },
                  }}
                >
                  {retreatsDetails
                    .slice(0)
                    .reverse()
                    .map((item, index) => (
                      <Box
                        onClick={() => {
                          handleGalleryModalOpen();
                          setClicked(index);
                          galleryref.current.handleChangeslide(index);
                        }}
                        className="gallery-card cursor-btn"
                        key={index}
                      >
                        <img
                          src={`${Offers_URL}${item}?w=162&auto=format`}
                          srcSet={`${Offers_URL}${item}?w=162&auto=format&dpr=2 2x`}
                        />
                      </Box>
                    ))}
                </Masonry>
              </div>
            </>
          </div>
          <Container maxWidth="xl">
            <div className="border-btm"></div>
          </Container>
          <section>
            <Container maxWidth="xl">
              <div className="day-trips-section">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Other Properties </h3>
                </Grid>
                <div className="btn-slider-div  ">
                  <Carousel
                    arrows={true}
                    ssr={true}
                    partialVisbile={MotorBikeArray.length < 3 ? false : true}
                    // sliderClass="centerslider"
                    responsive={responsive}
                    autoPlay={false}
                    infinite={false}
                    className=""
                  >
                    {MotorBikeArray.slice(0, 5).map((item, index) => (
                      <div
                        key={index}
                        className="bottom-cards-div"
                        data-aos={item.dataspeed}
                        data-aos-duration={item.speed}
                        data-aos-once="true"
                        onClick={() =>
                          navigate(`/${item.slug}?catagoryId=${item.id}`)
                        }
                      >
                        <OfferSlider {...item} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Container>
          </section>
          <Container maxWidth="xl">
            <div className="book-offer-div">
              <Grid
                container
                spacing={3}
                className="text-center section-head align-items-center"
              >
                <Grid md={3}></Grid>
                <Grid md={3}>
                  <h3>Offers</h3>
                </Grid>
                <Grid md={3}>
                  <Button className="white-btn">Book Now</Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
      <GalleryModal
        ref={galleryref}
        imagesOpen={imagesOpen}
        imagesGallery={retreatsDetails}
        handleGalleryModalClose={handleGalleryModalClose}
        handleGalleryModalOpen={handleGalleryModalOpen}
        clicked={clicked}
      />
    </>
  );
}
