import React from "react";
import IMAGES from "../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
const { bottomCardPicOne } = IMAGES;
function Image({ image, trastion, delay }) {
  return (
    <>
      <img
        alt="stay-banner"
        // delayTime={400}
        src={image} // use normal <img> attributes as props
        data-aos={trastion}
        data-aos-duration={delay}
      />

    </>
  );
}

export default Image;
