import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import { Row, Col, Button } from "react-bootstrap";
import { rightArrow, subMenuArrow } from "../../utils/svg.file";
import MapSidebar from "./mapSidebar";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BannerSlider from "../../components/BannerSlider";
import {
  checkretreatsDetailsData,
  ExclusiveBlock,
  exclusiveoffers,
  geetingDetailsData,
  retreatsDetailsData,
  RetreatSection,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom'
import RetreatDetails from "./readmoreLess";
const { vsklBanner, closeIcon } = IMAGES;
const BannerArray = [vsklBanner, vsklBanner];
const {
  travelBanner2,
  other1,
  other2,
  vsklOne,
  stayOne,
  vsklTwo,
  vsklT,
  vsklO,
  mapImg,
  vsklImg,
} = IMAGES;
export default function VSKLHome({ initialContent, maxLength }) {
  const navigate = useNavigate();
  const { slug } = useParams();

  const [searchParams] = useSearchParams();
  const [retreatsDetails, setRetreatsDetails] = useState([]);
  const [checkretreatsDetails, setCheckRetreatsDetails] = useState([]);
  const [retreatsRoomArray, setRetreatsRoomArray] = useState([]);
  const [openMapSidebar, setOpenMapSidebar] = useState(false);
  const [restaurantId, setRestaurantId] = useState("");
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [retreatsDetailssection, setRetreatsDetailssection] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleMapSidebarOpen = () => {
    // setOpenMapSidebar(true);
    setOpenMapSidebar(!openMapSidebar ? true : false);
  };

  const responsiveProperty = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  const responsiveExclusive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      // paritialVisibilityGutter: 40,
    },
  };

  const bigSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 400,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 300,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      // paritialVisibilityGutter: 40,
    },
  };
  const handleExclusiveBlock = async (id,type) => {
    setIslodingData(true);

    const response = await ExclusiveBlock(id,type);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };
  let rows = [];

  BlockCelebration?.map((item, index) => {
    rows.push({
      image: item.image,
      slug: item.other,
      subcategory: "Exculsive Offers",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      other: item.other,
      speed: "fade-up",
      dataspeed: "1500",
    });
  });
  const handleretreatsDetailssection = async () => {
    setIslodingData(true);

    const response = await RetreatSection();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setRetreatsDetailssection(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleretreatsDetailssection();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const MotorBikeArray = [];
  retreatsDetailssection?.forEach((item, index) => {
    if (Number(item.id) !== Number(restaurantId))
      MotorBikeArray.push({
        image: item.cardImage,
        subcategory: "RETREATS",
        title: item.title,
        shortDescription: item.shortDescription,
        id: item.id,
        slug: item.slug,
        speed: "3000",
        dataspeed: "fade-up",
      });
  });
  const handleretreatsDetails = async (catagoryId) => {
    const response = await retreatsDetailsData(catagoryId);

    if (response.remote === "success") {
      setRetreatsDetails(response.data.data[0]);
      let tempArr = response.data.roomArray;
      setRetreatsRoomArray(
        tempArr.sort((a, b) => Number(a.sequence) - Number(b.sequence))
      );
    } else {
    }
  };
  const handleCheckretreatsDetails = async (slug) => {
    const response = await checkretreatsDetailsData(slug);

    if (response.remote === "success") {

      setCheckRetreatsDetails(response.data.data);

    } else {
    }
  };
  useEffect(() => {
    handleCheckretreatsDetails(slug)
  }, [slug])
  useEffect(() => {

    if(checkretreatsDetails === null){
      navigate('/error')
    }
  }, [checkretreatsDetails])


  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setGettingData(response.data.data[0]);
    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("catagoryId");
    const type = 'retreats';
    setRestaurantId(catagoryId);
    handleretreatsDetails(catagoryId);
    handleExclusiveBlock(catagoryId,type);
    handlegettingDetails(catagoryId);
    window.scrollTo(0, 0);
  }, [searchParams]);
  const handleOpenLink = (link) => {
    let result = link.split("/")[0];
    if (result === "https:" || result === "http:") {
      window.open(link, "_blank");
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [keywordItem, setKeywordItem] = useState(false);

  useEffect(() => {
    if (slug === 'villa-samadhi-kuala-lumpur') {
      setKeywordItem(`best boutique urban retreat, luxury villa samadhi kuala lumpur, beach resort malaysia, beach resorts kuala lumpur, beach resorts near kuala lumpur, beach villa malaysia, beach villa tioman, beachfront bungalow, beachfront hotel malaysia, beachfront resort malaysia, best beach resort in malaysia for couple, best beach resorts malaysia, best boutique resort malaysia, best eco friendly resorts, best eco friendly resorts malaysia, best eco resort malaysia, best hotel for honeymoon malaysia`)
      const script = document.createElement("script");
      script.src = "https://static.sojern.com/sdk/latest/sojern.min.js";
      script.type = "text/javascript";
      script.async = true;

      const inlineScript = document.createElement("script");
      inlineScript.type = "text/javascript";
      inlineScript.innerHTML = `
        window.sjn = window.sjn || function() {
          (sjn.q = sjn.q || []).push(arguments);
        };
        sjn('initAndFire', 
            'advertiser', 
            '15207', 
            'tracking', { 
            context: { vertical: 'hotel' },
            params: {}
        });
      `;

      // Append both scripts to the body
      document.body.appendChild(script);
      document.body.appendChild(inlineScript);

      return () => {
        // Clean up script elements if the component unmounts
        document.body.removeChild(script);
        document.body.removeChild(inlineScript);
      };
    } else if (slug === 'japamala-resort-tioman-island') {
      // Load the Sojern SDK script
      const script = document.createElement('script');
      script.src = 'https://static.sojern.com/sdk/latest/sojern.min.js';
      script.type = 'text/javascript';
      script.async = true;
    
      // Inline script to initialize and fire the Sojern container
      const inlineScript = document.createElement('script');
      inlineScript.type = 'text/javascript';
      inlineScript.innerHTML = `
        window.sjn = window.sjn || function() { (sjn.q = sjn.q || []).push(arguments); };
        sjn('initAndFire', 
          'container', 
          '206', 
          'homePage', { 
            context: { vertical: 'hotel' },
            params: {
              propertyID: "65c451e2-21c7-454a-8fab-d3dd692d78e8",
              property: "JapaMala Resorts"
            }
          }
        );
      `;
    
      // Append the external and inline scripts to the body
      document.body.appendChild(script);
      document.body.appendChild(inlineScript);
    
      return () => {
        // Clean up script elements if the component unmounts
        document.body.removeChild(script);
        document.body.removeChild(inlineScript);
      };
    }
  }, [slug]);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{retreatsDetails.meta_title}</title>
        <meta name="description" content={retreatsDetails.meta_description} />
        <link
          rel="canonical"
          href={`https://www.samadhiretreats.com/${slug}`}
        />
        <meta name="keywords" content={keywordItem} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="travel-category vskl-home">
        <div className=" main-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={6}
                className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
              >
                {" "}
                <div className="">
                  <h3 className="cursor-btn">
                    {retreatsDetails && retreatsDetails.title !== undefined
                      ? `${retreatsDetails.title}, ${retreatsDetails.other}`
                      : ""}
                    <span className="ms-2">{subMenuArrow}</span>
                  </h3>
                </div>
              </Col>
              <Col lg={6}>
                <ul className="ps-0 pt-20">
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsDetails.slug}/rooms?retreatId=${retreatsDetails.id}`
                        )
                      }
                    >
                      Rooms
                    </Button>
                  </li>

                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsDetails.slug}/dining?retreatId=${retreatsDetails.id}`
                        )
                      }
                    >
                      {" "}
                      Dining
                    </Button>
                  </li>

                  <li>
                    {restaurantId !== "18" ? (
                      <>
                        <Button
                          onClick={() =>
                            navigate(
                              `/vskl-celebration?retreatId=${retreatsDetails.id}`
                            )
                          }
                        >
                          {" "}
                          Wellness
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                  
                    <Button
                      onClick={() =>
                        navigate(
                          `/exclusive-offer-details?retreatId=${retreatsDetails.id}&type=retreats`
                        )
                      }
                    >
                      Offers
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={() =>
                        navigate(
                          `/${retreatsDetails.slug}/gallery?retreatId=${retreatsDetails.id}`
                        )
                      }
                    >
                      Gallery
                    </Button>
                  </li>
                  <li>
                    <Button onClick={() => navigate(`/travels-tours`)}>
                      Activities
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div "
              data-aos="fade-up"
              data-aos-duration="3000"
              // // data-aos-once="true"
              // style={{
              //   backgroundSize: "100% 100%",
              //   margin: "0 22px",
              //   backgroundImage: `url(${vsklBanner})`,

              //   backgroundRepeat: "no-repeat",
              // }}
            >
              <img src={`${Offers_URL}${retreatsDetails.bannerImage}`} alt="" />
            </div>
          </Container>
          <div className="page-description-div">
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Grid
                  item
                  md={8}
                  xl={7}
                  className="page-description-data"
                  // data-aos-once="true"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h3 className="text-center   ">
                    {retreatsDetails.innertitle}
                  </h3>
                  <p
                    className="package_duration_texteditor"
                    dangerouslySetInnerHTML={{
                      __html: retreatsDetails.longDescription,
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </div>
          <div className="number-slider vskl-number-slide">
            <Container maxWidth="xl">
              <Row className="justify-content-md-end">
                <Col md={10} className=" btn-slider-div ">
                  <Carousel
                    speed={4000}
                    arrows={true}
                    ssr={true}
                    partialVisbile={retreatsRoomArray.length < 2 ? false : true}
                    // sliderClass="centerslider"
                    responsive={bigSlider}
                    autoPlay={false}
                    infinite={false}
                  >
                    {retreatsRoomArray.map((content, index) => {
                      return (
                        <div
                          className=" cursor-btn"
                          key={index}
                          // // data-aos-once="true"
                          // data-aos={content.dataspeed}
                          // data-aos-duration={content.speed}
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          <Grid className="card-design">
                            <div
                              className="images-room"
                              onClick={() =>
                                navigate(
                                  `/${retreatsDetails.slug}/rooms/${content.title}?retreatId=${retreatsDetails.id}&roomId=${content.id}`
                                )
                              }
                            >
                              {" "}
                              <img
                                src={`${Offers_URL}${content.banner}`}
                                alt=""
                              />
                            </div>

                            <Grid
                              className="cards heading"
                              onClick={() =>
                                navigate(
                                  `/${retreatsDetails.slug}/rooms/${content.title}?retreatId=${retreatsDetails.id}&roomId=${content.id}`
                                )
                              }
                            >
                              <h6>{retreatsDetails.place.toUpperCase()}</h6>
                              <h4>{content.title}</h4>

                              <p className="">{content.shortDescription}</p>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Carousel>
                </Col>
                <Col md={8}>
                  <div className="slider-count-text ">
                    <p
                      className="cursor-btn me-4"
                      onClick={() =>
                        navigate(
                          `/${retreatsDetails.slug}/rooms?retreatId=${retreatsDetails.id}`
                        )
                      }
                    >
                      View all rooms
                    </p>
                  </div>
                </Col>
              </Row>{" "}
            </Container>
            <Container maxWidth="xl">
              <div className="border-btm "></div>
            </Container>
          </div>
          <div className="day-trips-section room-div-slide">
            <Container maxWidth="xl">
              <div className="btn-slider-div shs">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsiveExclusive}
                  autoPlay={false}
                  infinite={true}
                >
                  {rows?.map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div"
                      // data-aos-once="true"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>
          <div className="map-section-div text-center">
            <Container maxWidth="xl">
              {" "}
              <div className="map-border-div border-dashed">
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  // data-aos-once="true"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <Grid md={8} item className="section-head mt-56">
                    <h3 className="geting-text">Getting Here</h3>
                  </Grid>
                  <Grid md={8} item>
                    <div className="main-map-div">
                      <div
                        className="package_duration_texteditor"
                        contentEditable="true"
                        dangerouslySetInnerHTML={{
                          __html: retreatsDetails.mapframe,
                        }}
                      ></div>
                    </div>
                    {gettingData ? (
                      <div
                        className="cards heading cursor-btn"
                        onClick={() => {
                          const formattedTitle = retreatsDetails.title
                            .replace(/\s+/g, "-")
                            .toLowerCase();
                          navigate(
                            `/${formattedTitle}/getting-here?retreatId=${retreatsDetails.id}`
                          );
                        }}
                      >
                        <h4 className="getting_text">Getting Here</h4>
                        <div className="truncated-text">
                         
                          {retreatsDetails?.mapDescription}
                        </div>
                        <p
                          className="cursor-btn"
                          // onClick={() => handleOpenLink(retreatsDetails.url)}
                          onClick={() => {
                            const formattedTitle = retreatsDetails.title
                              .replace(/\s+/g, "-")
                              .toLowerCase();
                            navigate(
                              `/${formattedTitle}/getting-here?retreatId=${retreatsDetails.id}`
                            );
                          }}
                        >
                          Find out more
                        </p>
                      </div>
                    ) : (
                      <div className="cards heading cursor-btn">
                        <h4 className="getting_text">Getting Here</h4>
                        <div className="truncated-text">
                          {retreatsDetails.mapDescription ? (
                            <>
                             
                              {retreatsDetails?.mapDescription}
                            </>
                          ) : (
                            <>
                            
                              {retreatsDetails?.mapDescription}
                            </>
                          )}
                         
                        </div>
                        <p
                          className="cursor-btn"
                          onClick={() => handleOpenLink(retreatsDetails.url)}
                        >
                          Find out more
                        </p>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className="border-btm mt-5"></div>
            </Container>
          </div>
          <div className="day-trips-section other-sect pb-120 slickimg">
            <Container maxWidth="xl">
              <Grid className="section-head mb-100">
                <h3 className="geting-text text-center">Other Properties</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={MotorBikeArray.length < 3 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsiveProperty}
                  autoPlay={false}
                  infinite={false}
                >
                  {MotorBikeArray?.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div "
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      // data-aos-once="true"
                      onClick={() => {
                        navigate(`/${item.slug}?catagoryId=${item.id}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div className="book-offer-div">
              <Grid
                container
                spacing={3}
                className="text-center section-head align-items-center mt-0"
              >
                <Grid md={3} item className="offer_sm_none"></Grid>
                <Grid md={3} item>
                  <h3>Offers</h3>
                </Grid>
                <Grid md={3} item>
                  <Button
                    className="white-btn"
                    onClick={() =>
                      navigate(
                        `/exclusive-offer-details?retreatId=${retreatsDetails.id}&type=retreats`
                      )
                    }
                  >
                    Discover
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}
