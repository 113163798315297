import React from "react";
import IMAGES from "../../assets/images";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Offers_URL } from "../../serverUrl";
const { bottomCardPicOne } = IMAGES;
function Image({ image, trastion, delay }) {
  return (
    <>
      <img src={`${Offers_URL}${image}`} alt="" data-aos={trastion} data-aos-duration={delay} loading="lazy" width={408} height={507}   priority={false} />
     
    </>
  );
}

export default Image;
