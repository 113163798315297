import { Container, Divider, duration, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import IMAGES from "../../assets/images";
import BannerSlider from "../../components/BannerSlider";
import ExclusiveBook from "../../components/exclusive-book";
import SubHeader from "../../components/layout/SubHeader";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import {
  geetingDetailsData,
  retreatsDetailsData,
  retreatsGalleryDetailsData,
} from "../../api/frontend";
import { Offers_URL } from "../../serverUrl";
import { Select } from "antd";
import { Button, Col, Row } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

const {
  RoomTypeBanner,
  celebrationBanner,
  crib1,
  crib2,
  crib3,
  crib4,
  crib5,
  vsklImg,
  food,
  vsklTwo,
} = IMAGES;
const RoomType = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchParams] = useSearchParams();
  const [retreatsRoomArray, setRetreatsRoomArray] = useState([]);
  const BannerArray = [RoomTypeBanner, celebrationBanner];
  const [retreatIds, setRetreatsid] = useState("");
  const [retreatslocation, setRetreatslocation] = useState("");
  const [retreatsslug, setRetreatsslug] = useState("");
  const [retreatsaccomodationBanner, setRetreatsaccomodationBanner] = useState(
    []
  );
  const [exclusiveOffers, setExclusiveOffers] = useState([]);
  const [retreatsaccomodationDescription, setRetreatsaccomodationDescription] =
    useState("");
  const [retreatsTitleData, setRetreatsTitleData] = useState("");
  const [retreatsMetaTitleData, setRetreatsMetaTitleData] = useState("");
  const [retreatsMetaDiscriptionData, setRetreatsMetaDiscriptionData] =
    useState("");
  const [isLoadingData, setIslodingData] = useState(true);
  const [booking, setBooking] = useState("");

  const exclusiveOffer = [];
  exclusiveOffers?.map((item) => {
    exclusiveOffer.push({
      image: item.image,
      subcategory: "OFFERS",
      title: item.title,
      shortDescription: item.shortDescription,
      id: item.id,
      other: item.other,
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const handleretreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsGalleryDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setExclusiveOffers(response.data.exclusive);
      setRetreatsaccomodationBanner(
        JSON.parse(response.data.data[0].accomodation_gallery)
      );
      setRetreatsaccomodationDescription(
        response.data.data[0].accomodation_description
      );
      setRetreatsid(response.data.data[0].id);
      setRetreatsslug(response.data.data[0].slug);
      setRetreatsTitleData(response.data.data[0].title);
      setRetreatsMetaTitleData(response.data.data[0].meta_title);
      setRetreatsMetaDiscriptionData(response.data.data[0].meta_description);
      setRetreatslocation(response.data.data[0].other);
      setBooking(response.data.data[0].booking);
    } else {
    }
  };

  const handleretRoomreatsDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await retreatsDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      let tempArr = response.data.roomArray;
      setRetreatsRoomArray(
        tempArr.sort((a, b) => Number(a.sequence) - Number(b.sequence))
      );
    } else {
    }
  };

  const [gettingData, setGettingData] = useState([]);

  const handlegettingDetails = async (catagoryId) => {
    setIslodingData(true);

    const response = await geetingDetailsData(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setGettingData(response.data.data[0]);
    } else {
    }
  };
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    handleretreatsDetails(catagoryId);
    handleretRoomreatsDetails(catagoryId);
    handlegettingDetails(catagoryId);
    if (catagoryId === "16") {
      const script = document.createElement("script");
      script.src = "https://static.sojern.com/sdk/latest/sojern.min.js";
      script.type = "text/javascript";
      script.async = true;

      const inlineScript = document.createElement("script");
      inlineScript.type = "text/javascript";
      inlineScript.innerHTML = `
    window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
    sjn('initAndFire', 
        'advertiser', 
        '15207', 
        'tracking', { 
        context: { vertical: 'hotel' },
        params: {}
    })
    `;

      // Append both scripts to the body
      document.body.appendChild(script);
      document.body.appendChild(inlineScript);

      return () => {
        // Clean up script elements if the component unmounts
        document.body.removeChild(script);
        document.body.removeChild(inlineScript);
      };
    } else if (catagoryId === "17") {
      // Load the external script for sjrn_autocx
      const script = document.createElement("script");
      script.src = "https://static.sojern.com/utils/sjrn_autocx.js";
      script.type = "text/javascript";
      script.async = true;

      const inlineScript = document.createElement("script");
      inlineScript.type = "text/javascript";
      inlineScript.innerHTML = `
        (function () {
          var params = {
            hpr: "JapaMala Resorts", /* Hotel Property */
            hpid: "65c451e2-21c7-454a-8fab-d3dd692d78e8", /* Property ID */
            sha256_eml: "", /* Hashed Email SHA256 */
            sha1_eml: "", /* Hashed Email SHA1 */
            md5_eml: "", /* Hashed Email MD5 */
            ccid: "", /* Client Cookie id */
            pt: "HOME_PAGE" /* Page Type - CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */
          };

          params.et = {"HOME_PAGE": null, "SEARCH": "hs", "PRODUCT": "hpr", "SHOPPING_CART": "hcart", "CONVERSION": "hc", "TRACKING": null}[params.pt] || '';
          
          try { params = Object.assign({}, sjrn_params || {}, params); } catch (e) {}

          var paramsArr = [];
          for (var key in params) {
            paramsArr.push(key + '=' + encodeURIComponent(params[key]));
          }

          var iframe = document.createElement('iframe');
          iframe.type = 'text/html';
          iframe.setAttribute('style', 'height:0; width:0; display:none;');
          iframe.async = true;
          iframe.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');

          document.body.appendChild(iframe);
        })();
      `;

      // Append the external and inline scripts to the body
      document.body.appendChild(script);
      document.body.appendChild(inlineScript);

      return () => {
        // Clean up script elements if the component unmounts
        document.body.removeChild(script);
        document.body.removeChild(inlineScript);
      };
    }
  }, [searchParams]);
  const handleOpenLink = (link) => {
    let result = link.split("/")[0];
    if (result === "https:" || result === "http:") {
      window.open(link, "_blank");
    } else {
      window.open(`https://${link}`, "_blank");
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{retreatsMetaTitleData}</title>
        <meta name="description" content={retreatsMetaDiscriptionData} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="room-type-page room-type-all ">
        <div className="main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div mb-5">
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={6}
                    className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                  >
                    <h3
                      className="cursor-btn"
                      onClick={() =>
                        navigate(`/${retreatsslug}?catagoryId=${retreatIds}`)
                      }
                    >
                      {retreatsTitleData && retreatsTitleData !== undefined
                        ? `${retreatsTitleData}, ${retreatslocation}`
                        : ""}

                      <span className="ms-2">{subMenuArrow}</span>
                    </h3>
                  </Col>
                  <Col lg={6}>
                    <ul className="ps-0 pt-20 ">
                      <li>
                       
                        <Button
                          className="active"
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/rooms?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Rooms
                        </Button>
                      </li>
                      <li>
                       
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/dining?retreatId=${retreatIds}`
                            )
                          }
                        >
                          {" "}
                          Dining
                        </Button>
                      </li>
                   
                      <li>
                       

                        {retreatIds !== 18 ? (
                          <>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/vskl-celebration?retreatId=${retreatIds}`
                                )
                              }
                            >
                              {" "}
                              Wellness
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </li>
                      <li>
                      
                        <Button
                          onClick={() =>
                            navigate(
                              `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                            )
                          }
                        >
                          Offers
                        </Button>
                      </li>
                      <li>
                      
                        <Button
                          onClick={() =>
                            navigate(
                              `/${retreatsslug}/gallery?retreatId=${retreatIds}`
                            )
                          }
                        >
                          Gallery
                        </Button>
                      </li>
                      <li>
                        <Button onClick={() => navigate(`/travels-tours`)}>
                          Activities
                        </Button>
                      </li>
                     
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div pb-0 bannerSlide"
              data-aos="fade-up"
              data-aos-duration="3000"
              // // data-aos-once="true"
            >
              <Container maxWidth="xl"></Container>
              <BannerSlider BannerArray={retreatsaccomodationBanner} />
            </div>
          </Container>
          <Container maxWidth="xl">
            <div
              className=" content-text-style "
              data-aos="fade-up"
              // data-aos-once="true"
              data-aos-duration="1000"
            >
              <Grid container justifyContent="center">
                <Grid items xl={6} lg={7}>
                  <h3 className="hedding-color text-center font-45 pb-109">
                    {retreatsTitleData}
                  </h3>
                  <p
                    className="package_duration_texteditor"
                    dangerouslySetInnerHTML={{
                      __html: retreatsaccomodationDescription,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
          <div className="content-cardbox">
            <Container maxWidth="xl">
              <Grid container spacing={4}>
                {retreatsRoomArray.map((items, index) => {
                  var gotIndex = index % 2 == 0;
                  return (
                    <>
                      {gotIndex ? (
                        <>
                          <Grid
                            className="cursor-btn normal-card-section"
                            item
                            xl={6}
                            lg={6}
                            key={index}
                          >
                            <div>
                              <div
                                className={`card-description mt-150  ${items.cardClass}`}
                                data-aos="fade-down"
                                data-aos-duration="1000"
                                // data-aos-once="true"
                                onClick={() => {
                                  const formattedTitle = items.title
                                    .replace(/\s+/g, "-")
                                    .toLowerCase();
                                  navigate(
                                    `/${retreatsslug}/rooms/${formattedTitle}?retreatId=${retreatIds}&roomId=${items.id}`
                                  );
                                  window.scrollTo(0, 0);
                                }}
                              >
                                <img
                                  src={`${Offers_URL}${items.cardImage}`}
                                  alt="crib"
                                  className="mb-55"
                                />
                                <div className="w-75">
                                  <h4>{items.title}</h4>
                                  <p>
                                   
                                    {items.shortDescription}
                                  </p>
                                 
                                </div>
                              </div>
                              <div className="btn-reserbox boxed ">
                                <button
                                  className="dashed-btn"
                                  onClick={() => handleOpenLink(booking)}
                                >
                                  Reservation
                                </button>
                              </div>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid
                          className="cursor-btn normal-card-section"
                          item
                          xl={6}
                          lg={6}
                          key={index}
                        >
                          <div
                            className={`card-description  ${items.cardClass}`}
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            // data-aos-once="true"
                            onClick={() => {
                              const formattedTitle = items.title
                                .replace(/\s+/g, "-")
                                .toLowerCase();
                              navigate(
                                `/${retreatsslug}/rooms/${formattedTitle}?retreatId=${retreatIds}&roomId=${items.id}`
                              );
                              window.scrollTo(0, 0);
                            }}
                          >
                            <img
                              src={`${Offers_URL}${items.cardImage}`}
                              alt="crib"
                              className="mb-55"
                            />
                            <div className="w-75">
                              <h4>{items.title}</h4>
                              <p>
                               
                                {items.shortDescription}
                              </p>
                             
                            </div>
                          </div>{" "}
                          <div className="btn-reserbox boxed ">
                            <button
                              className="dashed-btn"
                              onClick={() => handleOpenLink(booking)}
                            >
                              Reservation
                            </button>
                          </div>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </Grid>
            </Container>
          </div>
          <Container maxWidth="xl">
            <div className="day-trips-section ">
              <div className="btn-slider-div ">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={false}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                >
                  {exclusiveOffer.map((item, index) => (
                    <div
                      key={index}
                      // data-aos-once="true"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                      className={`bottom-cards-div  ${item.cardClass}`}
                      onClick={() => navigate(`/offers/${item.slug}`)}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>{" "}
          </Container>
          <Container maxWidth="xl">
            <div className="book-offer-div">
              <Grid
                container
                spacing={3}
                className="text-center section-head align-items-center"
              >
                <Grid md={3}></Grid>
                <Grid md={3}>
                  <h3>Offers</h3>
                </Grid>
                <Grid md={3}>
                  <Button
                    className="white-btn"
                    onClick={() =>
                      navigate(
                        `/exclusive-offer-details?retreatId=${retreatIds}&type=retreats`
                      )
                    }
                  >
                    Book Now
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};
export default RoomType;
