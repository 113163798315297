import React from "react";
import styles from "../../admin.module.css";
import { ProgressBar } from "react-bootstrap";
export default function LoaderMain() {
  return (
    <div className="top_loader_div">
     
      <div class="progress">
        <div class="progress-value"></div>
      </div>
    </div>
  );
}
