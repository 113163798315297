import { React, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import Slider from "react-slick";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { subMenuArrow } from "../../utils/svg.file";
import { celebrationBlock, showExperienceBannner, showExperienceIdsList, showExperienceList, showStoryTitle, showfeaturedstory } from "../../api/frontend";
import { Featured_URL, Offers_URL } from "../../serverUrl";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

const {
  experience1,
  experience2,
  experienceHome,
  exslider1,
  exslider2,
  exslider3,
  eventslider1,
  eventslider2,
  eventslider3,
  cultreslider1,
  cultreslider2,
  discoverSlider1,
  discoverSlider2,
  discoverSlider3,
  story,
} = IMAGES;

export default function ExperienceHome() {
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");
  const [experienceArray, SetexperienceArray] = useState([]);
  const [experienceListArray, SetExperienceListArray] = useState([]);
  const [bannerArray, SetbannerArray] = useState("");
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [storytitle, setStoryTitle] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleChange = (event) => {
    setPageName(event.target.value);
  };
  const handlefeaturedstory = async () => {
    setIslodingData(true);
 
    const id = '6';
    const response = await showExperienceIdsList(id);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  const handleExperienceBanner = async () => {
    setIslodingData(true);

    const response = await showExperienceBannner();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetbannerArray(response.data.data);
    } else {
    }
  };
  const handleStoryTitle = async () => {
    setIslodingData(true);

    const response = await showStoryTitle();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setStoryTitle(response.data.data);
    } else {
    }
  };

  const handleExperienceList = async () => {
    setIslodingData(true);

    const response = await showExperienceList();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      SetExperienceListArray(response.data.data);
    } else {
    }
  };
  const handleCelebrationBlock = async () => {
    setIslodingData(true);

    const response = await celebrationBlock();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handlefeaturedstory();
    handleExperienceList();
    handleExperienceBanner();
    handleCelebrationBlock();
    handleStoryTitle();
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  const responsiveDiscover = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  window.addEventListener("scroll", () => {
    let elem = document.getElementById("keyvisual");
    let scrollY = window.scrollY / 10;
    elem.style.backgroundSize = 100 + scrollY + "%";
  });

  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleMoveToCulture = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  let rows = [];

  experienceListArray?.forEach((item) => {
    // let type = "";
    // if (Number(item.category) === 1) {
    //   type = "SAMADHIAN";
    // } else if (Number(item.category) === 2) {
    //   type = "CULTURE";
    // } else if (Number(item.category) === 3) {
    //   type = "CELEBRATION";
    // } else if (Number(item.category) === 4) {
    //   type = "WELLNESS";
    // }
    rows.push({
      id: item.id,
      image: item.cardImage,
      subcategory: item.category,
      title: item.title,
      shortDescription: item.shortdescription,
      linktext: "Discover",
      speed: "1000",
      dataspeed: "fade-up",
    });
  });
  let Celebrationrows = [];

  BlockCelebration?.map((item, index) => {
    Celebrationrows.push({
      image: item.cardimage,
      subcategory: "CELEBRATIONS & EVENTS",
      title: item.title,
      shortDescription: item.description,
      id: item.id,
      speed: "fade-up",
      dataspeed: "1500",
    });
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{bannerArray[0]?.meta_title}</title>
        <meta name="description" content={bannerArray[0]?.meta_description} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="experience-page  experience-section-page">
        <div className="experience-bg">
          <div className="sub-header">
            <Row className="g-0">
              <Col
                lg={12}
                className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
              >
                <h3>
                  Stories
                  
                </h3>
              </Col>
            
            </Row>
          </div>
          
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
              // data-aos-once="true"
            >
              <Grid container>
                <Grid item lg={12} className="banner-section">
                  <img src={`${Offers_URL}${bannerArray[0]?.banner}`} alt="" />
                </Grid>
              </Grid>
            </div>
          </Container>
          <Container maxWidth="xl">
            <div className="page-description ">
              <Grid container justifyContent="center" className=" ">
                <Grid
                  item
                  md={7}
                  xl={7}
                  className="page-description-data text-center"
                  data-aos="fade-up"
                  // data-aos-duration="2000"
                  data-aos-once="true"
                >
                  <p
                    className="package_duration_texteditor"
                    dangerouslySetInnerHTML={{
                      __html: bannerArray[0]?.description,
                    }}
                  />
                </Grid>
              </Grid>
            </div>{" "}
          </Container>
          <div class="new_div">
            {storytitle.map((item) => (
              <>
                <div className="section exp-bg">
                  <div className="section " id={item.id}>
                    <Container maxWidth="xl">
                      <Grid
                        container
                        spacing={2}
                        className="text-center d-block py-200 "
                      >
                        <Grid
                          item
                          sm={12}
                          xl={12}
                          lg={12}
                          data-aos="fade-up"
                          data-aos-duration="3000"
                          data-aos-once="true"
                        >
                          <h3 className="text-center heading-experience  font-62">
                            {item.title}
                          </h3>
                        </Grid>
                      </Grid>
                    </Container>
                  </div>
                  <Container maxWidth="xl">
                    <div className="border-btm  samadhian-div">
                      <div className="exprcne btn-slider-div">
                        <Carousel
                          arrows={true}
                          ssr={false}
                          partialVisbile={rows.length < 3 ? false : true}
                          // sliderClass="centerslider"
                          responsive={responsive}
                          autoPlay={false}
                          infinite={false}
                        >
                          {rows?.map((content, image) => {
                            return (
                              content.subcategory === item.title && (
                                <div
                                  className="slider__item "
                                  data-aos={content.dataspeed}
                                  data-aos-once="true"
                                  data-aos-duration={content.speed}
                                  onClick={() =>
                                    navigate(
                                      `/experiences-details?experience=${content.id}`
                                    )
                                  }
                                >
                                  <OfferSlider {...content} />
                                </div>
                              )
                            );
                          })}
                        </Carousel>
                      </div>
                    </div>
                  </Container>
                </div>
              </>
            ))}
            <div className="experience-div-section">
              <Container maxWidth="xl">
                <Grid className="text-center section-head">
                  <h3>Stories</h3>
                </Grid>
                <Slider
                  {...experienceSlide}
                  className="experience-slider celebrate-customer-div"
                >
                  {experienceArray?.map((item, index) => (
                    <Grid className="testimonial-type-card">
                      <Grid
                        container
                        spacing={{ md: 6, xs: 2 }}
                        className="align-items-center d-flex "
                        data-aos="fade-up"
                        data-aos-once="true"
                        data-aos-duration="10000"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={4}>
                          <div>
                            {" "}
                            <Grid className="cards heading">
                              <h4>{item.title}</h4>
                              <p>{item.shortdescription}</p>
                              <Link to="#">Discover</Link>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <img src={`${Offers_URL}${item.banner}`} alt="" />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Slider>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
